import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from 'src/components/Layout/mdx-layout.tsx';
export const _frontmatter = {
  "title": "Discord",
  "subtitle": "ディスコードについて",
  "description": "弱いzineはDiscordサーバーをオープンしました。こちらからどなたでも気軽に参加してください。",
  "slug": "discord",
  "prevlink": "about",
  "prevtitle": "弱いzineって？"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Discordへの案内`}</h2>
    <p>{`Discordを利用したことはありますか？話したり、チャットしたり、集まったりすることに特化した掲示板のようなものです。`}</p>
    <p>{`弱いzineはDiscordサーバーをオープンしました。こちらからどなたでも気軽に参加してください。`}</p>
    <p><a parentName="p" {...{
        "href": "https://discord.gg/ufbx6jsGNc",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`弱いzineのDiscordサーバーに参加する`}</a></p>
    <h2>{`あそびかた`}</h2>
    <p>{`自己紹介とかしなくていいので気軽に発言してみてください。これにつきます。`}</p>
    <p>{`SNSのタイムラインや広告に疲れてる自分に気づいたときに訪れてみてください。`}</p>
    <p>{`誰かと話したいときは「#パーティ」などのボイスチャンネルにいってみてください。誰か来るかもしれません。`}</p>
    <p>{`使い方で困ったこと、わからないことがあればお知らせカテゴリの「＃困ってませんか？」に書き込んでください。手助けがあります。`}</p>
    <p>{`もし誰かの発言の内容が怖いと思ったらDMで＠osamuosanaiまで教えてください。話し合ってみます。`}</p>
    <p>{`自分で遊びが作れるかもと思ったらそのアイデアを弱いLABの「企画、アイデアボックス」に投げてみて下さい。世界がひっくり返るかも。`}</p>
    <p>{`以下ガイドマップになります。`}</p>
    <p>{`弱いzineのdiscordサーバーには現在主に３つのカテゴリが存在しています。`}</p>
    <h2>{`「心おきなくいられる場所」`}</h2>
    <p>{`ここには居場所がいくつかありますね。`}</p>
    <p>{`例えば立ち寄った人が今日の体調について書き込んでいく場所があります。それに労いや睡眠に対するアドバイスをくれる人がいます。やさしいですね。医者がここに常駐していてくれたら…。`}</p>
    <p>{`例えば立ち寄った人が季節について感じたことを書き込んでいく居場所があります。季節の変わり目の変化に気づくことって意外とうれしい。そんなささやかなうれしみを共有するかわいい人たち。`}</p>
    <p>{`例えば立ち寄った人が最近楽しくないと思ったことを書き込んでいく場所があります。ショックだったことは誰かに聞いてもらって慰めてもらいましょう。ガビーン。`}</p>
    <h2>{`「デイリー」`}</h2>
    <p>{`今日何食べた？twitterに聞かれそうな質問ですね。`}</p>
    <p>{`SNSからの避難所としてできることの一つに日常の共有があります。誰かがさっき食べたものの写真を見て今日の献立の参考にしてみたり、あなたと暮らしている植物のかわいさ自慢をしてみたり、いつのまにか肥大化してしまったタイムライン上では忘れてしまったゆるいFlexを楽しめます。現在は今日のwordleの結果を報告しあうのがやはり流行っている模様。`}</p>
    <h2>{`「弱いLAB」`}</h2>
    <p>{`弱いzineで何ができるのか。このdiscord上から何を始めることができるのか。そういった企画会議を空いてる時間にやり続けていく場所です。`}</p>
    <p>{`弱いzineがやりたいことはインターネットに公園のような場所を作る事です。僕らの標榜する公園とはそこに来る人たちが勝手に遊びを生み出す場所の事です。そのための「仕組み」を創造していきましょう。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      